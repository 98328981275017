import {createTheme, responsiveFontSizes} from '@mui/material/styles';

let theme = createTheme({
    typography: {
        htmlFontSize: 10,
    },
});
theme = responsiveFontSizes(theme);

export default theme;
