const picturesACC = [
    {
        order: 1,
        legend: 'Team Training mit dem Mustang und dem 992',
        name: 'training_mustang_992',
    },
    {
        order: 2,
        legend: 'Ein Teil unserer mittlerweile ca. 20 iRacing Liveries',
        name: 'liveries',
    },
    {
        order: 3,
        legend: 'Mit dem Audi hat damals in ACC alles angefangen ...',
        name: 'audi',
    },
    {
        order: 4,
        legend: 'Für den beliebten 992 gab es zuletzt ein Livery Update',
        name: '992_livery_update',
    },
    {
        order: 5,
        legend: 'Unsere zwei Autos bei der RennWelten Renndurance',
        name: 'renndurance_daytona',
    },
    {
        order: 6,
        legend: 'Unsere erste iRacing Liga und gleich 4 Audis am Start',
        name: 'cas_liga_audis',
    },
    {
        order: 7,
        legend: 'Schwer zu beherrschen der "PCUP" - Porsche 992 Cup',
        name: 'pcup',
    },
    {
        order: 8,
        legend: 'Training für das iRacing Special Event "Roar"',
        name: 'vorbereitung_roar',
    },
    {
        order: 9,
        legend: 'Auch mal eine "Open Wheel" Livery mit dem FIA F4',
        name: 'fiaf4',
    },
];

export default picturesACC.sort((a, b) => a.order > b.order ? 1 : -1);