import React from 'react';
import {makeStyles} from '@mui/styles';
import {Button, Card, CardActions, CardContent, CardMedia, Typography} from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '15px',
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            margin: '25px auto !important',
            '@media (orientation: landscape)': {
                width: '90%',
            },
            '@media (orientation: portrait)': {
                width: '85%',
            },
        },
        [theme.breakpoints.up('sm')]: {
            margin: '15px auto !important',
            '@media (orientation: landscape)': {
                width: '46%',
            },
            '@media (orientation: portrait)': {
                width: '70%',
            },
        },
        [theme.breakpoints.up('md')]: {
            margin: '25px auto !important',
            '@media (orientation: landscape)': {
                width: '46%',
            },
            '@media (orientation: portrait)': {
                width: '43%',
            },
        },
        [theme.breakpoints.up('lg')]: {
            margin: '25px auto !important',
            width: '23%',
        },
        borderRadius: '3px 19px 3px 19px !important',
    },
    triangleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    triangleACC: {
        display: 'inline-block',
        width: '0px',
        height: '0px',
        borderStyle: 'solid',
        borderWidth: '70px 90px 0 0',
        borderColor: '#cc0b0b transparent transparent transparent',
        transform: 'rotate(0deg)',
    },
    triangleNotACC: {
        display: 'inline-block',
        width: '0px',
        height: '0px',
        borderStyle: 'solid',
        borderWidth: '70px 90px 0 0',
        borderColor: '#ffffff transparent transparent transparent',
        transform: 'rotate(0deg)',
    },
    simACC: {
        top: '-65px',
        left: '3px',
        position: 'relative',
        color: '#e8f9f0',
        fontWeight: 'bold !important',
        fontSize: '0.9rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    triangleIR: {
        display: 'inline-block',
        width: '0px',
        height: '0px',
        borderStyle: 'solid',
        borderWidth: '0 90px 70px 0',
        borderColor: 'transparent #2f0cb1 transparent transparent',
        transform: 'rotate(0deg)',
    },
    triangleNotIR: {
        display: 'inline-block',
        width: '0px',
        height: '0px',
        borderStyle: 'solid',
        borderWidth: '0 90px 70px 0',
        borderColor: 'transparent #ffffff transparent transparent',
        transform: 'rotate(0deg)',
    },
    simIR: {
        top: '3px',
        right: '-33px',
        position: 'relative',
        color: '#e8f9f0',
        fontWeight: 'bold !important',
        fontSize: '0.9rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    media: {
        margin: '0 1%',
        minHeight: '520px',
        [theme.breakpoints.down('md')]: {
            minHeight: '500px',
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '390px',
        },
    },
    content: {
        width: '100% !important',
        padding: '0 10px 5px 10px !important',
        backgroundColor: '#e8f9f0',
        textAlign: 'left',
    },
    bottonLine: {
        width: '100% !important',
        height: '60px !important',
        padding: '0 10px !important',
        backgroundColor: '#e8f9f0',
        textAlign: 'left',
    },
    flagGround: {
        display: 'flex',
        backgroundColor: '#e8f9f0',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flag: {
        height: '22px',
        margin: '6px',
    },
    typo: {
        maxWidth: '99%',
        height: '140px',
        [theme.breakpoints.down('sm')]: {
            '@media (orientation: landscape)': {
                height: '110px',
            },
        },
    },
    name: {
        margin: '0 !important',
        color: '#142017 !important',
        fontSize: '1.4rem !important',
        fontWeight: 'bold !important',
    },
    pos: {
        margin: '4px 2px !important',
        color: '#142017 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    desc: {
        margin: '0 2px !important',
        color: '#142017 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    button: {
        color: '#57f97a !important',
        backgroundColor: '#0a0f0a !important',
        borderRadius: '12px !important',
        marginTop: '7px !important',
        marginRight: '4px !important',
        fontSize: '0.8rem !important',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.7rem !important',
        },
    },
}));

export default function DriverCard({driver}) {
    const classes = useStyles();

    const flagNumber = `/assets/numbers/mf_flaggen_nummern_black_${driver.number}.png`;
    const imageUrl = process.env.PUBLIC_URL + `/assets/profiles/mf_comic_racedriver_hp_${driver.number}.png`;
    const lfmUrl = `https://lowfuelmotorsport.com/profile/${driver.lfmId}`;

    return (
        <Card className={classes.root} elevation={24}>
            <div className={classes.flagGround}>
                <img src={flagNumber} className={classes.flag}/>
            </div>
            <CardMedia
                className={classes.media}
                image={imageUrl}
                title="Fahrer Bild"
            >
                <div className={classes.triangleWrapper}>
                    {driver.currentSim.includes('ACC') && (
                        <div className={classes.triangleACC}>
                            <Typography className={classes.simACC}>
                                ACC
                            </Typography>
                        </div>
                    )}
                    {!driver.currentSim.includes('ACC') && (
                        <div className={classes.triangleNotACC}>
                        </div>
                    )}
                    {driver.currentSim.includes('iRacing') && (
                    <div className={classes.triangleIR}>
                        <Typography className={classes.simIR}>
                            iRacing
                        </Typography>
                    </div>
                    )}
                    {!driver.currentSim.includes('iRacing') && (
                        <div className={classes.triangleNotIR}>
                        </div>
                    )}
                </div>
            </CardMedia>
            <CardContent className={classes.content}>
                <div className={classes.typo}>
                    <Typography className={classes.name}>
                        {driver.firstName} {driver.lastName}
                    </Typography>
                    <Typography className={classes.pos}>
                        {driver.position}
                    </Typography>
                    <Typography className={classes.desc}>
                        Bei uns im Team seit: {driver.since}
                    </Typography>
                    <Typography className={classes.desc}>
                        Aktuelle Simulation: {driver.currentSim}
                    </Typography>
                </div>
            </CardContent>
            <CardActions className={classes.bottonLine}>
                {driver.lfmId && (
                    <Button className={classes.button} href={lfmUrl} target="_blank">LFM Profil</Button>
                )}
            </CardActions>
        </Card>
    );
}
