import {makeStyles} from '@mui/styles';
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    useMediaQuery
} from '@mui/material';
import {
    Download,
    Filter,
    FormatListBulleted,
    Groups,
    GroupWork,
    Home, Instagram,
    SortSharp,
    SportsMotorsports
} from '@mui/icons-material';
import {Link} from 'react-scroll';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        height: '50px',
    },
    appbar: {
        background: '#0a0f0a !important',
    },
    appbarWrapper: {
        width: '90%',
        margin: '0 auto',
    },
    appbarTitle: {
        flexGrow: '1',
    },
    homeButton: {
        padding: '0 !important',
    },
    homeIcon: {
        height: '40px',
    },
    icon: {
        color: '#57f97a',
        fontSize: '2rem',
    },
    overlay: {
        margin: '2px',
        backgroundColor: 'transparent !important',
    },
    list: {
        padding: '0 !important',
    },
    listItem: {
        backgroundColor: '#0a0f0a',
        color: '#e8f9f0',
        border: '1px solid',
        borderColor: '#e8f9f0 #0a0f0a',
    },
    listIcon: {
        backgroundColor: '#0a0f0a',
        color: '#57f97a',
    },
    paper: {
        background: 'transparent !important',
    }
}));

const usePathname = () => {
    const location = useLocation();
    return location.pathname;
}

export default function Header() {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 760px)');

    const [menu, setMenu] = useState(false);

    const toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMenu(open);
    };

    const list = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const path = usePathname();
        if (path.includes('impressum')) {
            return <Box
                className={classes.overlay}
                role="presentation"
            >
                <List className={classes.list}>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/">
                            <ListItemIcon>
                                <Home className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Startseite"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/downloads" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <Download className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Downloads"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="https://www.instagram.com/motorsports_factory/" target="_blank" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <Instagram className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Instagram"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        } else if (path.includes('downloads')) {
            return <Box
                className={classes.overlay}
                role="presentation"
            >
                <List className={classes.list}>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/">
                            <ListItemIcon>
                                <Home className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Startseite"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="https://www.instagram.com/motorsports_factory/" target="_blank" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <Instagram className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Instagram"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/impressum" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <FormatListBulleted className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Impressum"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        } else {
            return <Box
                className={classes.overlay}
                role="presentation"
            >
                <List className={classes.list}>
                    <ListItem disablePadding className={classes.listItem}>
                        <Link to="drivers" smooth={true} offset={-30} delay={400} onClick={toggleDrawer(false)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <SportsMotorsports className={classes.listIcon}/>
                                </ListItemIcon>
                                <ListItemText primary="Unsere Fahrer"/>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <Link to="team" smooth={true} offset={-30} delay={400} onClick={toggleDrawer(false)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <GroupWork className={classes.listIcon}/>
                                </ListItemIcon>
                                <ListItemText primary="Unser Team"/>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <Link to="communities" smooth={true} offset={-30} delay={400} onClick={toggleDrawer(false)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Groups className={classes.listIcon}/>
                                </ListItemIcon>
                                <ListItemText primary="Communities & Friends"/>
                            </ListItemButton>
                        </Link>
                    </ListItem>

                    <ListItem disablePadding className={classes.listItem}>
                        <Link to="picturesACC" smooth={true} offset={-30} delay={400} onClick={toggleDrawer(false)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Filter className={classes.listIcon}/>
                                </ListItemIcon>
                                <ListItemText primary="Bilder"/>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/downloads" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <Download className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Downloads"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="https://www.instagram.com/motorsports_factory/" target="_blank" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <Instagram className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Instagram"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={classes.listItem}>
                        <ListItemButton href="/impressum" onClick={toggleDrawer(true)}>
                            <ListItemIcon>
                                <FormatListBulleted className={classes.listIcon}/>
                            </ListItemIcon>
                            <ListItemText primary="Impressum"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        }
    };

    return (
        <div className={classes.root} id="header">
            <AppBar className={classes.appbar} elevation={0}>
                <Toolbar className={classes.appbarWrapper}>
                    <div className={classes.appbarTitle} onClick={event => window.location.href = '/'}>
                        <Button className={classes.homeButton}>
                            <img src="assets/icon.png" alt="logo" className={classes.homeIcon}/>
                        </Button>
                    </div>
                    <IconButton onClick={toggleDrawer(true)}>
                        <SortSharp className={classes.icon}/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer anchor={isMobile ? 'bottom' : 'right'}
                    classes={{paper: classes.paper}}
                    open={menu}
                    onClose={toggleDrawer(false)}
                    onClick={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </div>
    )
}
