import {makeStyles} from '@mui/styles';
import {Collapse, IconButton, Typography} from '@mui/material';
import {Link as Scroll} from 'react-scroll';
import {ExpandMore} from '@mui/icons-material';
import {useEffect, useState} from 'react';
import driversRegular from '../static/driversRegular';
import driversSubstitute from '../static/driversSubstitute';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        maxWidth: '1100px',
        margin: '10px auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            '@media (orientation: landscape)': {
                maxWidth: '80%',
            },
            '@media (orientation: portrait)': {
                maxWidth: '80%',
            },
        },
    },
    text: {
        padding: '3px 5%',
        color: '#e8f9f0 !important',
        fontSize: '1.3rem !important',
        [theme.breakpoints.up('sm')]: {
            '@media (orientation: landscape)': {
                fontSize: '0.8rem !important',
            },
            '@media (orientation: portrait)': {
                fontSize: '1.3rem !important',
            },
        },
        [theme.breakpoints.up('md')]: {
            '@media (orientation: landscape)': {
                fontSize: '1.3rem !important',
            },
        },
        [theme.breakpoints.down('lg')]: {
            '@media (orientation: landscape)': {
                fontSize: '0.8rem !important',
            },
            '@media (orientation: portrait)': {
                fontSize: '1rem !important',
            },
        },
    },
    logo: {
        width: '65%',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.up('sm')]: {
            '@media (orientation: landscape)': {
                width: '45%',
            },
        },
    },
    goDown: {
        color: '#57f97a',
        backgroundColor: '#0a0f0a',
        marginTop: '10px',
        marginBottom: '110px',
        minWidth: '100px',
        fontSize: '3.2rem !important',
        borderRadius: '12px',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
}));

export default function Welcome() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(true);
    }, []);

    const drivers = driversRegular.length + driversSubstitute.length;

    return (
        <div className={classes.root}>
            <Collapse
                in={checked}
                timeout={1500}
            >
                <img src="assets/logo.png" alt="logo" className={classes.logo}/>
                <Typography className={classes.text}>
                    Wir sind ein Team von {drivers} Simracern (und ein paar Freunden im Discord), das
                    regelmäßig gemeinsam trainiert und Rennen fährt. Wir sind hauptsächlich in&nbsp;
                    <span className={classes.noWrap}>Assetto Corsa Competizione</span> & iRacing unterwegs. Nach dem Privatleben
                    und der Arbeit, folgt bei uns der Spaß auf der Strecke.
                </Typography>
                <Typography className={classes.text}>
                    Hier auf der Seite, seht ihr wer für uns fährt, wo wir so unterwegs sind, Bilder von ACC & iRacing,
                    sowie den Download Bereich für die Fahrer.
                </Typography>
                <Typography className={classes.text}>
                    <strong>Also einfach mal runter scrollen, um mehr über uns zu erfahren...</strong>
                </Typography>
                <Scroll to="drivers" smooth={true} offset={-30} delay={200}>
                    <IconButton>
                        <ExpandMore className={classes.goDown}/>
                    </IconButton>
                </Scroll>
            </Collapse>
        </div>
    );
}
