import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import comsAndFriends from '../static/comsAndFriends';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px 1% 10px 1%',
        justifyContent: 'center',
        textAlign: 'center',
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1000px',
        padding: '0 3%',
        margin: '0px auto !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    images: {
        maxWidth: '1100px',
        margin: '25px auto !important',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
    },
    imgBlock: {
        margin: '1% 2%',
        padding: '10px',
        minWidth: '200px',
        color: '#e8f9f0 !important',
        fontSize: '0.8rem !important',
        backgroundColor: '#142017',
        borderRadius: '3px 19px 3px 19px',
        '& a': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '2% 10%',
            fontSize: '0.7rem !important',
        },
    },
    imgText: {
        color: '#e8f9f0 !important',
        fontSize: '0.9rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    img: {
        height: '70px',
        margin: '0px 0px 11px 0px',
        padding: '0',
        [theme.breakpoints.down('sm')]: {
            height: '60px',
        },
    }
}));

export default function CommunitiesAndFriends() {
    const classes = useStyles();

    const listCom = []
    comsAndFriends.filter(x => x.subject === 'com')
        .forEach(com => listCom.push(
            <div className={classes.imgBlock}>
                <a href={com.link} target="_blank">
                    <img className={classes.img} src={com.logoPath}/>
                    <br/>
                    <span className={classes.imgText}>{com.name}</span>
                </a>
            </div>
    ));

    const listFriend = []
    comsAndFriends.filter(x => x.subject === 'friend')
        .forEach(com => listFriend.push(
            <div className={classes.imgBlock}>
                <a href={com.link} target="_blank">
                    <img className={classes.img} src={com.logoPath}/>
                    <br/>
                    <span className={classes.imgText}>{com.name}</span>
                </a>
            </div>
        ));

    return (
        <div className={classes.root} id="communities">
            <Typography className={classes.title}>Communities & Friends</Typography>
            <Typography className={classes.text}>Bei folgenden Communities fahren wir aktiv in Ligen mit,
                oder nehmen / nahmen an speziellen Events teil.
            </Typography>
            <div className={classes.images}>
                {listCom}
            </div>
            <Typography className={classes.text}>Hierunter sind Teams & Communities, mit denen wir befreundet sind
                und / oder zusammen bei Events fahren / gefahren sind.
            </Typography>
            <div className={classes.images}>
                {listFriend}
            </div>
        </div>
    );
}
