import React from 'react';
import DriverCard from './DriverCard';
import driversRegular from '../static/driversRegular';
import driversSubstitute from '../static/driversSubstitute';
import driversAlumni from '../static/driversAlumni';
import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import DriverCardSubstitute from './DriverCardSubstitute';
import DriverCardAlumni from "./DriverCardAlumni";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px 1% 10px 1%',
        margin: '10px auto',
        justifyContent: 'center',
        maxWidth: '1500px',
        textAlign: 'center',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            display: 'block',
        },
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1000px',
        padding: '0 3%',
        margin: '0px auto !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
}));

export default function DriverList() {
    const classes = useStyles();

    const listRegular = []
    driversRegular.forEach(driver => listRegular.push(<DriverCard key={driver.order} driver={driver}/>))

    const listSubstitute = []
    driversSubstitute.forEach(driver => listSubstitute.push(<DriverCardSubstitute key={driver.order} driver={driver}/>))

    const listAlumni = []
    driversAlumni.forEach(driver => listAlumni.push(<DriverCardAlumni key={driver.order} driver={driver}/>))

    return (
        <div className={classes.root} id="drivers">
            <Typography className={classes.title}>Unsere Stammfahrer</Typography>
            <div className={classes.list}>
                {listRegular}
            </div>
            {/* <Typography className={classes.title}>Unsere Ersatzfahrer</Typography>
            <div className={classes.list}>
                {listSubstitute}
            </div> */}
            <Typography className={classes.title}>Unsere Alumni</Typography>
            <Typography className={classes.text}>(Ehemalige Fahrer denen wir einiges zu verdanken haben)</Typography>
            <div className={classes.list}>
                {listAlumni}
            </div>
        </div>
    );

}
