import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0',
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        margin: '15px 6% !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1200px',
        padding: '0 1%',
        margin: '15px 3% !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    disclaimer: {
        maxWidth: '1300px',
        padding: '1% 1%',
        margin: '15px 3% 30px 3% !important',
        color: '#e8f9f0 !important',
        backgroundColor: '#142017',
        border: '2px solid #0a0f0a',
        fontSize: '1.1rem !important',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    link: {
        color: '#e8f9f0',
    }
}));

export default function Impressum() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Impressum</Typography>
            <Typography className={classes.disclaimer}>
                Disclaimer: Ich versichere, dass ich mit dieser Seite kein Geld verdiene - im Gegensatz, mein Team
                kostet mich eher Geld ;) Ich habe hier keine Affiliate-Links o. Ä., sondern lediglich Verlinkungen zu
                anderen Communities oder befreundeten Teams (für beides trage ich keinerlei Verantwortung - siehe
                "Haftung für Links"). Für das Anzeigen von Informationen meiner Fahrer, habe ich mir deren Einverständis
                geholt. Sie können das auch jederzeit bei mir widerrufen. Des Weiteren werden hier keinerlei Cookies
                gesetzt, Google Fonts geladen, oder irgendwelche Daten gespeichert. Somit sollte für diese Seite kein
                Impressum verpflichtend sein. Ich habe jedoch das "Standard-Impressum" angehängt. Sie erreichen mich
                unter angegebener Email-Adresse.
            </Typography>
            <Typography className={classes.text}>
                Hier folgen die Angaben gemäß § 5 TMG
            </Typography>
            <Typography className={classes.text}>
                <strong>Verantwortlicher</strong><br/>
                Michael Wellner<br/>
                <a className={classes.link}
                   href="mailto:info@m1well.com?subject=Bezüglich Impressum der Motorsports Factory">info@m1well.com</a>
            </Typography>
            <Typography className={classes.text}>
                <strong>Haftung für Inhalte</strong><br/>
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß
                § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§
                8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
                fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </Typography>
            <Typography className={classes.text}>
                <strong>Haftung für Links</strong><br/>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </Typography>
            <Typography className={classes.text}>
                <strong>Urheberrecht</strong><br/>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </Typography>
            <Typography className={classes.text}>
                <strong>Datenschutz</strong><br/>
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben
                werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht möglich.
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
                Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
            </Typography>
            <Typography className={classes.text}>
                Impressum vom <a className={classes.link} href="https://www.impressum-generator.de" target="_blank">
                Impressum Generator</a> der <a className={classes.link}
                                               href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/"
                                               target="_blank">Kanzlei Hasselbach</a>
            </Typography>
        </div>
    );
}
