const drivers = [
    {
        order: 1,
        firstName: 'Max',
        lastName: 'Meinecke',
        number: 218,
        range: '12/2022 - 10/2023',
        sim: 'ACC'
    },
    {
        order: 2,
        firstName: 'Fabio',
        lastName: 'Steyer',
        number: 619,
        range: '12/2022 - 10/2023',
        sim: 'ACC'
    },
    {
        order: 3,
        firstName: 'Valentin',
        lastName: 'Goppelt',
        number: 216,
        range: '03/2023 - 11/2023',
        sim: 'ACC & iRacing'
    },
    {
        order: 4,
        firstName: 'Maurice',
        lastName: 'Rockefeller',
        number: 311,
        range: '12/2022 - 12/2023',
        sim: 'ACC'
    }
];

export default drivers.sort((a, b) => a.order > b.order ? 1 : -1);