import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import dateFormat from 'dateformat';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px 1% 10px 1%',
        justifyContent: 'center',
        textAlign: 'center',
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1000px',
        padding: '0 3%',
        margin: '0px auto !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    img: {
        height: '190px',
        margin: '1%',
        borderRadius: '3px 19px 3px 19px',
        backgroundColor: '#142017',
        [theme.breakpoints.down('sm')]: {
            height: '120px',
            margin: '3%',
        },
    }
}));

function daysBetween(date1String, date2String) {
    return (new Date(date2String) - new Date(date1String)) / (1000 * 3600 * 24);
}

export default function Team() {
    const classes = useStyles();

    const counter = daysBetween('2022-11-19', dateFormat(Date.now(), 'isoDate'));

    return (
        <div className={classes.root} id="team">
            <Typography className={classes.title}>
                Unser Team
            </Typography>
            <Typography className={classes.text}>
                <strong>... seit {counter} Tagen ...</strong>
            </Typography>
            <Typography className={classes.text}>
                Gegründet wurde Motorsports Factory am 19.11.2022 von Michael & Eric. Gemeinsam hatten wir die
                Vision, ein ACC Team (damals bevorzugt im Audi) aufzubauen, welches aktiv fährt und sich regelmäßig auf
                Discord im Voice Chat trifft. Mittlerweile haben wir ein paar mehr Fahrer und beherrschen natürlich
                auch mehrere Autos.
            </Typography>
            <Typography className={classes.text}>
                Die Liveries sind alle selbst erstellt. Zusätzlich gibt es ein paar eigens entwickelte
                Discord-Bots, die uns den Alltag erleichtern. Abgerundet wird das ganze mit einem
                gut gepflegten Instagram Account.
            </Typography>
            <Typography className={classes.text}>
                Unsere "Hausstrecke" in ACC, welche wir auch als Detail auf jedem Autodach in ACC haben, ist Kyalami.
                Dort sind wir am 04.12.2022 unser erstes 4,5H Endurance Rennen mit dem neu gegründeten Team gefahren.
            </Typography>
            <img className={classes.img} src="assets/kyalami.png"/>
        </div>
    );
}
