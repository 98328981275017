import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import picturesACC from '../static/picturesACC';
import picturesIR from '../static/picturesIR';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px 1% 0 1%',
        justifyContent: 'center',
        textAlign: 'center',
        '& .carousel .carousel-slider .control-arrow': {
            color: 'green !important',
        }
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '5% 0',
        },
    },
    carousel: {
        maxWidth: '1111px',
        margin: '0 auto',
        padding: '1%',
    },
    legend: {
        color: '#e8f9f0',
        backgroundColor: '#142017',
        margin: '0',
        height: '65px',
        padding: '3px',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem !important',
            height: '60px',
        },
    },
    banner: {
        boxShadow: '0 4px 8px 0 rgba(10, 15, 10, 0.2), 0 6px 20px 0 rgba(10, 15, 10, 0.19)',
        width: '75%',
        maxWidth: '1200px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));

export default function Pictures() {
    const classes = useStyles();

    const listACC = []
    const listIR = []
    picturesACC.forEach(picture => listACC.push(<div key={picture.order}>
        <img src={`assets/pictures/mf_hp_pictures_acc_${picture.name}.png`}/>
        <p className={classes.legend}>{picture.legend}</p>
    </div>))

    picturesIR.forEach(picture => listIR.push(<div key={picture.order}>
        <img src={`assets/pictures/mf_hp_pictures_ir_${picture.name}.png`}/>
        <p className={classes.legend}>{picture.legend}</p>
    </div>))

    return (
        <div className={classes.root} id="picturesACC">
            <Typography className={classes.title}>Bilder ACC</Typography>
            <Carousel className={classes.carousel} infiniteLoop transitionTime="2000"
            >
                {listACC}
            </Carousel>
            <Typography className={classes.title}>Bilder iRacing</Typography>
            <Carousel className={classes.carousel} infiniteLoop transitionTime="2000"
            >
                {listIR}
            </Carousel>
            <img className={classes.banner} src={`assets/pictures/mf_banner_hp_v2.png`}/>
        </div>
    );
}
