const liveriesACC = [
    {
        order: 1,
        text: 'GT3 Default Liveries:<br>Audi R8 LMS GT3 Evo 2, Mercedes AMG GT3 Evo<br>Lamborghini Huracán GT3 Evo 2, McLaren 720S GT3 Evo<br>Porsche 992 GT3 R, Ferrari 296 GT3<br>Ford Mustang GT3, Aston Martin AMR V8 GT3<br>Porsche 991 II GT3 R',
        code: 'GT3',
        active: true,
    },
    {
        order: 2,
        text: 'CUP Default Liveries:<br>Porsche 992 GT3 Cup',
        code: 'CUP',
        active: true,
    },
    {
        order: 3,
        text: 'TCX Default Liveries:<br>BMW M2 Club Sport Racing',
        code: 'TCX',
        active: true,
    }
];

export default liveriesACC.sort((a, b) => a.order > b.order ? 1 : -1);
