const comsAndFriends = [
    {
        order: 1,
        link: 'https://lowfuelmotorsport.com',
        logoPath: '/assets/communities_friends/lfm_black.png',
        name: 'Low Fuel Motorsport',
        subject: 'com',
        active: true,
    },
    {
        order: 2,
        link: 'https://rennwelten.online',
        logoPath: '/assets/communities_friends/rennwelten.png',
        name: 'RennWelten',
        subject: 'com',
        active: true,
    },
    {
        order: 3,
        link: 'https://instagram.com/old.man.standing',
        logoPath: '/assets/communities_friends/old_man_standing.png',
        name: 'Old Man Standing',
        subject: 'com',
        active: true,
    },
    {
        order: 4,
        link: 'https://www.instagram.com/checkeredflag_de',
        logoPath: '/assets/communities_friends/checkered_flag.png',
        name: 'Checkered Flag',
        subject: 'com',
        active: true,
    },
    {
        order: 5,
        link: 'https://www.instagram.com/projekt_tiefseetaucher',
        logoPath: '/assets/communities_friends/tiefseetaucher.png',
        name: 'Projekt Tiefseetaucher',
        subject: 'friend',
        active: true,
    },
    {
        order: 6,
        link: 'https://instagram.com/old.man.standing',
        logoPath: '/assets/communities_friends/old_man_standing.png',
        name: 'Old Man Standing',
        subject: 'friend',
        active: true,
    },
    {
        order: 7,
        link: 'https://linktr.ee/pitspeedracing',
        logoPath: '/assets/communities_friends/pitspeedracing.png',
        name: 'Pit Speed-Racing',
        subject: 'friend',
        active: true,
    }
];

export default comsAndFriends.sort((a, b) => a.order > b.order ? 1 : -1);