const picturesACC = [
    {
        order: 1,
        legend: 'LFM Endu Donington mit 2 neuen Mustangs',
        name: 'lfm_mustangs',
    },
    {
        order: 2,
        legend: 'Shooting mit den "Ursprings-Liveries"',
        name: 'liveries',
    },
    {
        order: 3,
        legend: 'Schnell aber nicht leicht zu fahren - der Porsche 992',
        name: '992',
    },
    {
        order: 4,
        legend: 'Komplexe aber schöne Livery - der Lambo Huracán Evo 2',
        name: 'lamboevo2',
    },
    {
        order: 5,
        legend: 'Das perfekt Allround Auto - der Aston Martin V8',
        name: 'astonv8',
    },
    {
        order: 6,
        legend: 'THE BEAST - der Mercedes AMG Evo mit unserer Livery',
        name: 'amgevo',
    },
    {
        order: 7,
        legend: 'Der Evo unserer \'Jugendliebe\' - McLaren 720S Evo',
        name: '720evo',
    },
    {
        order: 8,
        legend: 'Zaungast beim "Tanz in den Mai" auf dem Nürburgring',
        name: 'nbr_zaungast',
    },
    {
        order: 9,
        legend: 'Motorsports Factory trifft auf RennWelten Renndurance',
        name: 'mf_trifft_rw',
    },
    {
        order: 10,
        legend: 'Halbe Motorsports Factory beim Anbremsen für T1',
        name: 'barcelona_t1',
    },
    {
        order: 11,
        legend: 'Cooles Event mit OMS & PSR in Barcelona',
        name: 'oms_barcelona',
    },
    {
        order: 12,
        legend: 'Kleines Trainings-Event mit PSR & OMS',
        name: 'psr_kyalami',
    },
    {
        order: 13,
        legend: 'M2 Renn-Action auf dem Hungaroring mit Michael',
        name: 'm2_hungaro',
    },
];

export default picturesACC.sort((a, b) => a.order > b.order ? 1 : -1);