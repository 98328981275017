import {Backdrop, CircularProgress} from '@mui/material';

function BackdropLoadingSpinner(props) {
    return (
        <Backdrop sx={{color: '#142017', zIndex: (theme) => theme.zIndex.drawer + 1}} open={props.open}>
            <CircularProgress sx={{color: '#57f97a !important'}} size="15vh"/>
        </Backdrop>
    );
}

export default BackdropLoadingSpinner;
