import React from 'react';
import {makeStyles} from '@mui/styles';
import {Button, Card, CardActions, CardContent, Typography} from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '15px',
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            margin: '25px auto !important',
            '@media (orientation: landscape)': {
                width: '90%',
            },
            '@media (orientation: portrait)': {
                width: '85%',
            },
        },
        [theme.breakpoints.up('sm')]: {
            margin: '15px auto !important',
            '@media (orientation: landscape)': {
                width: '46%',
            },
            '@media (orientation: portrait)': {
                width: '70%',
            },
        },
        [theme.breakpoints.up('md')]: {
            margin: '25px auto !important',
            '@media (orientation: landscape)': {
                width: '46%',
            },
            '@media (orientation: portrait)': {
                width: '43%',
            },
        },
        [theme.breakpoints.up('lg')]: {
            margin: '25px auto !important',
            width: '23%',
        },
        borderRadius: '3px 19px 3px 19px !important',
    },
    media: {
        margin: '0 1%',
        minHeight: '520px',
        [theme.breakpoints.down('md')]: {
            minHeight: '500px',
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '390px',
        },
    },
    content: {
        width: '100% !important',
        padding: '0 10px 15px 10px !important',
        backgroundColor: '#e8f9f0',
        textAlign: 'left',
    },
    flagGround: {
        display: 'flex',
        backgroundColor: '#e8f9f0',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flag: {
        height: '22px',
        margin: '6px',
    },
    typo: {
        maxWidth: '99%',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            '@media (orientation: landscape)': {
                height: '110px',
            },
        },
    },
    name: {
        margin: '0 !important',
        color: '#142017 !important',
        fontSize: '1.4rem !important',
        fontWeight: 'bold !important',
    },
    pos: {
        margin: '4px 2px !important',
        color: '#142017 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    desc: {
        margin: '0 2px !important',
        color: '#142017 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
}));

export default function DriverCardSubstitute({driver}) {
    const classes = useStyles();

    const flagNumber = `/assets/numbers/mf_flaggen_nummern_black_${driver.number}.png`;

    return (
        <Card className={classes.root} elevation={24}>
            <div className={classes.flagGround}>
                <img src={flagNumber} className={classes.flag}/>
            </div>
            <CardContent className={classes.content}>
                <div className={classes.typo}>
                    <Typography className={classes.name}>
                        {driver.firstName} {driver.lastName}
                    </Typography>
                    <Typography className={classes.desc}>
                        Zeitraum: {driver.range}
                    </Typography>
                    <Typography className={classes.desc}>
                        Damalige Simulation: {driver.sim}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
}
