import {makeStyles, ThemeProvider} from '@mui/styles';
import {CssBaseline} from '@mui/material';
import Header from './components/Header';
import theme from './theme';
import Footer from './components/Footer';
import Content from './components/Content';
import {Route, Routes, Navigate} from 'react-router-dom';
import Impressum from './components/Impressum';
import Downloads from './components/Downloads';
import Setups from './components/Setups';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        minHeight: 'calc(100vh - 60px)',
        minWidth: '345px',
        background: 'linear-gradient(145deg, rgba(20,32,23,1) 20%,  rgba(20,32,23,0.95) 60%, rgba(20,32,23,0.85) 100%)',
    },
    content: {
        paddingBottom: '7rem',
    },
    footer: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
    }
}));

export default function App() {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className={classes.root}>
                <Header/>
                <div className={classes.content}>
                    <Routes>
                        <Route path="/" element={<Content/>}/>
                        <Route path="/setups" element={<Setups/>}/>
                        <Route path="/downloads" element={<Downloads/>}/>
                        <Route path="/impressum" element={<Impressum/>}/>
                        <Route path="/*" element={<Navigate to="/"/>}/>
                    </Routes>
                </div>
                <div className={classes.footer}>
                    <Footer/>
                </div>

            </div>
        </ThemeProvider>
    );
}
