import {makeStyles} from '@mui/styles';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert,
    Avatar,
    Box,
    Checkbox,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Snackbar,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CloudDownload, ExpandMore} from '@mui/icons-material';
import parse from 'html-react-parser'
import wallpaper from '../static/wallpaper';
import liveriesACC from '../static/liveriesACC';
import liveriesIR from '../static/liveriesIR';
import BackdropLoadingSpinner from './BackdropLoadingSpinner';

// const BASE_URL = 'http://localhost:7100'
const BASE_URL = 'https://mwfp.m1well.com'
const MF_TOKEN = 'a39b633f-51a4-42f1-84e2-2db7c3fc54f2';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0',
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        margin: '15px 6% !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1200px',
        padding: '0 1%',
        margin: '15px 3% !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    tabtext: {
        padding: '0 1%',
        margin: '15px 3% !important',
        color: '#e8f9f0 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    disclaimer: {
        maxWidth: '1300px',
        padding: '1% 1%',
        margin: '15px 3% 30px 3% !important',
        color: '#e8f9f0 !important',
        backgroundColor: '#142017',
        border: '2px solid #0a0f0a',
        fontSize: '1.1rem !important',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    tokenErrorText: {
        maxWidth: '1200px',
        padding: '1% 1%',
        margin: '5px 3% 15px 3% !important',
        color: 'red !important',
        backgroundColor: '#142017',
        border: '2px solid #0a0f0a',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    tab: {
        '& .MuiTabs-indicator': {backgroundColor: '#57f97a !important'},
        '& .MuiTab-root': {color: '#e8f9f0 !important'},
        '& .Mui-selected': {color: '#e8f9f0 !important', backgroundColor: '#0a0f0a !important'},
    },
    panel: {
        maxWidth: '1000px',
        margin: '1px auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    list: {
        width: '70%',
        margin: '0 auto !important',
        backgroundColor: '#142017',
        color: '#e8f9f0 !important',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    listItem: {
        padding: '7px 5px',
    },
    listText: {
        color: '#e8f9f0 !important',
        fontSize: '0.9rem !important',
        padding: '7px 1px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem !important',
            padding: '5px 0px !important',
        },
    },
    downloadButton: {
        color: '#57f97a',
        backgroundColor: '#0a0f0a',
        marginTop: '10px',
        minWidth: '100px',
        fontSize: '3.2rem !important',
        borderRadius: '12px',
    },
    popovertext: {
        padding: '3%',
        fontSize: '0.9rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem !important',
        },
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    wallpaperExample: {
        width: '80%',
        margin: '1% auto',
        backgroundColor: '#142017',
        boxShadow: '0 4px 8px 0 rgba(10, 15, 10, 0.2), 0 6px 20px 0 rgba(10, 15, 10, 0.19)',
        [theme.breakpoints.down('sm')]: {
            height: '150px',
            margin: '2%',
        },
    },
    accordion: {
        backgroundColor: 'rgba(10, 15, 10, 0.3) !important',
        margin: '15px 6% !important',
        color: '#e8f9f0',
    },
    accordionHeader: {
        backgroundColor: '#0a0f0a !important',
        color: '#e8f9f0 !important',
        fontSize: '1.0rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem !important',
        },
    },
    icon: {
        color: '#57f97a',
        fontSize: '2rem',
    },
    circular: {
        color: '#57f97a !important',
    }
}));

export default function Downloads() {

    useEffect(() => {
        fetch(BASE_URL + '/token', {
            method: 'GET',
            headers: {'Content-Type': 'text/plain', 'x-mf-token': MF_TOKEN},
        })
            .then(response => {
                if (response.status === 500 || response.status === 404 || response.status === 403 || response.status === 400) {
                    setDownloadTokenError(response.status);
                    return;
                }
                for (let pair of response.headers.entries()) {
                    if (pair[0] === 'x-download-token') {
                        setDownloadToken(pair[1]);
                    }
                }
            })
    }, []);

    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const [listChecked, setListChecked] = useState([]);

    const [downloadToken, setDownloadToken] = useState(null);
    const [downloadTokenError, setDownloadTokenError] = useState(0);
    const [downloadErrorSnack, setDownloadErrorSnack] = useState(0);
    const [inputErrorSnack, setInputErrorSnack] = useState(false);
    const [downloadPending, setDownloadPending] = useState(false);
    const [downloadSuccess, setDownloadSuccess] = useState(false);

    const [liveryNumberInput, setLiveryNumberInput] = useState('');
    const [liveryIracingIdInput, setLiveryIracingIdInput] = useState('');
    const [liveryCodeInput, setLiveryCodeInput] = useState('');

    const handleLiveryNumberInputChange = event => {
        setLiveryNumberInput(event.target.value);
    };

    const handleLiveryIracingIdInputChange = event => {
        setLiveryIracingIdInput(event.target.value);
    };

    const handleLiveryCodeInputChange = event => {
        setLiveryCodeInput(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleToggle = value => () => {
        if (value.active) {
            const currentIndex = listChecked.indexOf(value);
            const newChecked = [...listChecked];
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setListChecked(newChecked);
        }
    };

    const handleWallpaperDownload = () => {
        let fileName;
        setDownloadPending(true);

        if (!downloadToken) {
            setDownloadErrorSnack(999);
            setDownloadPending(false);
            return;
        }

        fetch(BASE_URL + '/mf/wallpaper', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-mf-token': MF_TOKEN, 'x-download-token': downloadToken},
            body: JSON.stringify({list: listChecked.map(element => element.name)})
        })
            .then(response => {
                if (response.status === 500 || response.status === 404 || response.status === 403 || response.status === 401 || response.status === 400) {
                    downloadEnd(false, response.status);
                    return;
                }
                fileName = response.headers.get('x-name');
                return response.blob()
            })
            .then(response => {
                if (fileName) {
                    const blob = new Blob([response], {type: 'application/zip'});
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    downloadEnd(true, 200);
                }
            });
    }

    const handleLiveriesAccDownload = () => {
        let fileName;
        setDownloadPending(true);

        if (!downloadToken) {
            setDownloadErrorSnack(999);
            setDownloadPending(false);
            return;
        }

        fetch(BASE_URL + '/mf/liveries/acc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-mf-token': MF_TOKEN,
                'x-download-token': downloadToken,
                'x-code': liveryCodeInput,
                'x-driver-number': liveryNumberInput,
            },
            body: JSON.stringify({list: listChecked.map(element => element.code)})
        })
            .then(response => {
                if (response.status === 500 || response.status === 404 || response.status === 403 || response.status === 401 || response.status === 400) {
                    downloadEnd(false, response.status);
                    return;
                }
                fileName = response.headers.get('x-name');
                return response.blob()
            })
            .then(response => {
                if (fileName) {
                    const blob = new Blob([response], {type: 'application/zip'});
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    downloadEnd(true, 200);
                }
            });
    }

    const handleLiveriesIracingDownload = () => {
        let fileName;
        setDownloadPending(true);

        if (!downloadToken) {
            setDownloadErrorSnack(999);
            setDownloadPending(false);
            return;
        }

        fetch(BASE_URL + '/mf/liveries/iracing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-mf-token': MF_TOKEN,
                'x-download-token': downloadToken,
                'x-code': liveryCodeInput,
                'x-iracing-id': liveryIracingIdInput,
            },
            body: JSON.stringify({list: listChecked.map(element => element.code)})
        })
            .then(response => {
                if (response.status === 500 || response.status === 404 || response.status === 403 || response.status === 401 || response.status === 400) {
                    downloadEnd(false, response.status);
                    return;
                }
                fileName = response.headers.get('x-name');
                return response.blob()
            })
            .then(response => {
                if (fileName) {
                    const blob = new Blob([response], {type: 'application/zip'});
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    downloadEnd(true, 200);
                }
            });
    }

    const showTokenError = () => {
        if (downloadTokenError !== 0) {
            return (
                <Typography className={classes.tokenErrorText}>
                    Fehler mit dem Token {downloadTokenError}
                </Typography>
            )
        }
        return <div></div>;
    }

    function SnackTransition(props) {
        return <Grow {...props} />;
    }

    function errorMessage() {
        if (inputErrorSnack) {
            setInputErrorSnack(false);
            return 'Fahrernummer (1-999) oder Code (9-stellig) im falschen Format!';
        }
        return `Fehler beim Download (${downloadErrorSnack})`
    }

    function successMessage() {
        return 'Download war erfolgreich!'
    }

    function downloadEnd(success, status) {
        setTimeout(() => {
            if (success) {
                setDownloadSuccess(true);
                setListChecked([]);
            } else {
                setDownloadErrorSnack(status);
            }
            setDownloadPending(false);
        }, 2000);
    }

    const handleClose = () => {
        setDownloadErrorSnack(0);
        setDownloadSuccess(false);
    };

    const alertChooser = () => {
        if (downloadErrorSnack !== 0 || inputErrorSnack) {
            return <Alert severity="error" sx={{width: '100%'}} onClose={handleClose}>{errorMessage()}</Alert>;
        }
        if (downloadSuccess) {
            return <Alert severity="success" sx={{width: '100%'}} onClose={handleClose}>{successMessage()}</Alert>;
        }
    }

    const resetFields = () => {
        setListChecked([]);
        setInputErrorSnack(false);
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Downloads</Typography>
            <Typography className={classes.disclaimer}>
                Disclaimer: Alles was hier bereitgestellt wird, wurde von mir oder dem Team selbst erstellt. Somit
                dürfen wir auch entscheiden, wo und wie wir es verbreiten. Bei Fehlern mit dem Download, gerne mit dem
                Fehlercode an Michael melden.
            </Typography>
            {showTokenError()}
            <Tabs className={classes.tab} value={tabIndex} onChange={handleTabChange} centered>
                <Tab label="Liveries" onClick={resetFields}/>
                <Tab label="Wallpaper" onClick={resetFields}/>
            </Tabs>
            <Snackbar
                open={(downloadErrorSnack !== 0 || downloadSuccess)}
                autoHideDuration={3000}
                TransitionComponent={SnackTransition}
                onClose={handleClose}
                key="error"
            >
                {alertChooser()}
            </Snackbar>
            <Box className={classes.panel}>
                {tabIndex === 0 && (
                    <Box>
                        <Typography className={classes.tabtext}>
                            Nachfolgend sind die Livery Ordner für das Default Design von unseren Autos, sowie für
                            gewisse Events. Die Liveries sind alle durch einen Code gesichert. Falls du ein Fahrer
                            bist oder andere Interessen an der Livery hast, kannst du bei Michael einen Code anfragen.
                            <br/> Wir haben viele ACC Liveries, sowie neuerdings auch ein paar iRacing Liveries.
                        </Typography>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                className={classes.accordionHeader}
                                expandIcon={<ExpandMore className={classes.icon} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >
                                    ACC Liveries
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Typography className={classes.tabtext}>
                                    Grobe Anleitung: 1. Downloaden, 2. Dateien des "Cars" Ordner in den lokalen ACC "Cars"
                                    Ordner kopieren, 3. Ordner aus dem "Liveries" Ordner in den lokalen ACC "Liveries" Ordner
                                    kopieren. ACHTUNG: Falls es ein Update einer bestehenden Livery ist, dann müsst ihr folgende
                                    Dateien noch aus dem spezifischen Liveries Ordner löschen: decals_0, decals_1, sponsors_0,
                                    sponsors_1. -> Bei Fragen einfach an Michael wenden.
                                </Typography>
                                <Typography className={classes.tabtext}>
                                    Einfach Code & Fahrzeugnummer eingeben, danach bei gewünschten Liveries den Haken
                                    setzen und downloaden.&nbsp;
                                    <i>Solltest du einen Code haben, aber kein Fahrer sein, dann ist das ein "public code",
                                        da gibt es dann keine Cars Datei und dann wird die Fahrzeugnummer nicht benötigt.</i>
                                </Typography>
                                <Box sx={{
                                    display: 'flex', justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}>
                                    <TextField
                                        label="Download Code"
                                        id="download-code"
                                        inputProps={{maxLength: 9}}
                                        value={liveryCodeInput}
                                        onChange={handleLiveryCodeInputChange}
                                        sx={{
                                            margin: '5px 20px 20px 20px',
                                            width: '170px',
                                            '& .MuiInputBase-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiInputLabel-root': {color: '#e8f9f0'},
                                            '& .MuiOutlinedInput-root': {
                                                '& > fieldset': {borderColor: '#e8f9f0'},
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused': {
                                                '& > fieldset': {
                                                    borderColor: '#e8f9f0'
                                                }
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: '#e8f9f0'
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="Nummer"
                                        id="driver-number"
                                        inputProps={{maxLength: 3}}
                                        value={liveryNumberInput}
                                        onChange={handleLiveryNumberInputChange}
                                        sx={{
                                            margin: '5px 20px 20px 20px',
                                            width: '110px',
                                            '& .MuiInputBase-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiInputLabel-root': {color: '#e8f9f0'},
                                            '& .MuiOutlinedInput-root': {
                                                '& > fieldset': {borderColor: '#e8f9f0'},
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused': {
                                                '& > fieldset': {
                                                    borderColor: '#e8f9f0'
                                                }
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: '#e8f9f0'
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                </Box>
                                <List className={classes.list} dense>
                                    {liveriesACC.map(value => {
                                        const labelId = `checkbox-list-secondary-label-${value.order}`;
                                        return (
                                            <ListItem
                                                key={value.order}
                                                onClick={handleToggle(value)}
                                                className={classes.listItem}
                                                secondaryAction={
                                                    <Checkbox
                                                        sx={{
                                                            backgroundColor: '#0a0f0a',
                                                            color: '#e8f9f0',
                                                            '&.Mui-checked': {
                                                                color: '#57f97a',
                                                            },
                                                        }}
                                                        edge="end"
                                                        checked={listChecked.indexOf(value) !== -1}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemText classes={{primary: classes.listText}} id={labelId}>
                                                        {parse(value.text)}
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <div
                                    hidden={(downloadTokenError !== 0 || listChecked.length === 0 || liveryCodeInput.length < 9)}>
                                    <IconButton onClick={handleLiveriesAccDownload}>
                                        <CloudDownload className={classes.downloadButton}/>
                                    </IconButton>
                                </div>

                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                className={classes.accordionHeader}
                                expandIcon={<ExpandMore className={classes.icon} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >
                                    iRacing Liveries
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Typography className={classes.tabtext}>
                                    Grobe Anleitung: 1. Downloaden, 2. Danach in Trading Paints unter "My Paints" das
                                    Auto auswählen und bei "Paint Options" das "Choose new paint..." klicken und die
                                    car_ID.tga des entsprechenden Autos hochladen - danach nochmal dort drauf und
                                    auf "Add spec map" klicken und die car_spec_ID.mip des Autos hochladen.
                                    -> Bei Fragen einfach an Michael wenden.
                                </Typography>
                                <Typography className={classes.tabtext}>
                                    Einfach Code & iRacing ID eingeben, danach bei gewünschten Liveries den Haken
                                    setzen und downloaden.&nbsp;
                                </Typography>
                                <Box sx={{
                                    display: 'flex', justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}>
                                    <TextField
                                        label="Download Code"
                                        id="download-code"
                                        inputProps={{maxLength: 9}}
                                        value={liveryCodeInput}
                                        onChange={handleLiveryCodeInputChange}
                                        sx={{
                                            margin: '5px 20px 20px 20px',
                                            width: '170px',
                                            '& .MuiInputBase-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiInputLabel-root': {color: '#e8f9f0'},
                                            '& .MuiOutlinedInput-root': {
                                                '& > fieldset': {borderColor: '#e8f9f0'},
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused': {
                                                '& > fieldset': {
                                                    borderColor: '#e8f9f0'
                                                }
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: '#e8f9f0'
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="iRacing ID"
                                        id="iracing-id"
                                        inputProps={{maxLength: 6}}
                                        value={liveryIracingIdInput}
                                        onChange={handleLiveryIracingIdInputChange}
                                        sx={{
                                            margin: '5px 20px 20px 20px',
                                            width: '110px',
                                            '& .MuiInputBase-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiInputLabel-root': {color: '#e8f9f0'},
                                            '& .MuiOutlinedInput-root': {
                                                '& > fieldset': {borderColor: '#e8f9f0'},
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused': {
                                                '& > fieldset': {
                                                    borderColor: '#e8f9f0'
                                                }
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: '#e8f9f0'
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: '#e8f9f0'
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                </Box>
                                <List className={classes.list} dense>
                                    {liveriesIR.map(value => {
                                        const labelId = `checkbox-list-secondary-label-${value.order}`;
                                        return (
                                            <ListItem
                                                key={value.order}
                                                onClick={handleToggle(value)}
                                                className={classes.listItem}
                                                secondaryAction={
                                                    <Checkbox
                                                        sx={{
                                                            backgroundColor: '#0a0f0a',
                                                            color: '#e8f9f0',
                                                            '&.Mui-checked': {
                                                                color: '#57f97a',
                                                            },
                                                        }}
                                                        edge="end"
                                                        checked={listChecked.indexOf(value) !== -1}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemText classes={{primary: classes.listText}} id={labelId}>
                                                        {parse(value.text)}
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <div
                                    hidden={(downloadTokenError !== 0 || listChecked.length === 0 || liveryCodeInput.length < 9)}>
                                    <IconButton onClick={handleLiveriesIracingDownload}>
                                        <CloudDownload className={classes.downloadButton}/>
                                    </IconButton>
                                </div>

                            </AccordionDetails>
                        </Accordion>


                        <BackdropLoadingSpinner open={downloadPending}/>
                    </Box>
                )}
                {tabIndex === 1 && (
                    <Box>
                        <Typography className={classes.tabtext}>
                            Für jedes Auto habe ich ein Wallpaper in der Größe 3440x1440 Pixel erstellt. Es ist für
                            jeden ausdrücklich erlaubt, sich ein oder mehrere Wallpaper runterzuladen. Außerdem gibt
                            es noch ein paar andere Wallpaper.
                        </Typography>
                        <Typography className={classes.tabtext}>
                            Einfach bei gewünschten Wallpaper den Haken setzen und dann downloaden.
                        </Typography>
                        <img className={classes.wallpaperExample}
                             src="assets/wallpaper/mf_wallpaper_sm_gt3_128.png"/>
                        <img className={classes.wallpaperExample}
                             src="assets/wallpaper/mf_wallpaper_sm_gt3_liveries.png"/>
                        <img className={classes.wallpaperExample}
                             src="assets/wallpaper/mf_wallpaper_sm_gt3_liveries_vohi.png"/>
                        <List className={classes.list} dense>
                            {wallpaper.map(value => {
                                const labelId = `checkbox-list-secondary-label-${value.order}`;
                                return (
                                    <ListItem
                                        key={value.order}
                                        onClick={handleToggle(value)}
                                        className={classes.listItem}
                                        secondaryAction={
                                            <Checkbox
                                                sx={{
                                                    backgroundColor: '#0a0f0a',
                                                    color: '#e8f9f0',
                                                    '&.Mui-checked': {
                                                        color: '#57f97a',
                                                    },
                                                }}
                                                edge="end"
                                                checked={listChecked.indexOf(value) !== -1}
                                                inputProps={{'aria-labelledby': labelId}}
                                            />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText classes={{primary: classes.listText}} id={labelId}>
                                                {parse(value.text)}
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <div
                            hidden={(downloadTokenError !== 0 || listChecked.length === 0)}>
                            <IconButton onClick={handleWallpaperDownload}>
                                <CloudDownload className={classes.downloadButton}/>
                            </IconButton>
                        </div>
                        <BackdropLoadingSpinner open={downloadPending}/>
                    </Box>
                )}
            </Box>
        </div>
    );
}
