import {makeStyles} from '@mui/styles';
import {Fab, Grid, IconButton, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {Add, Delete} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0',
    },
    title: {
        color: '#e8f9f0 !important',
        fontSize: '1.5rem !important',
        margin: '15px 6% !important',
        padding: '1% 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem !important',
            padding: '3% 0',
        },
    },
    text: {
        maxWidth: '1200px',
        padding: '0 1%',
        margin: '15px 3% !important',
        color: '#e8f9f0 !important',
        fontSize: '1.1rem !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    disclaimer: {
        maxWidth: '1300px',
        padding: '1% 1%',
        margin: '15px 3% 30px 3% !important',
        color: '#e8f9f0 !important',
        backgroundColor: '#142017',
        border: '2px solid #0a0f0a',
        fontSize: '1.1rem !important',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem !important',
        },
    },
    panel: {
        maxWidth: '1000px',
        margin: '1px auto',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    uploadButton: {
        color: '#57f97a !important',
        backgroundColor: '#0a0f0a !important',
        minHeight: '30px',
        minWidth: '40px',
        margin: '2px 10px',
    },
}));

export default function Setups() {
    const classes = useStyles();
    const [width, setWidth] = useState(window.innerWidth);
    const [files, setFiles] = useState([]);

    const handleFileUpload = e => {
        const fileReader = new FileReader();
        const fileName = e.target.files[0].name;
        fileReader.readAsText(e.target.files[0], 'UTF-8');
        fileReader.onload = e => {
            setFiles(prev => [...prev, {name: fileName, content: e.target.result}])
        };
    };

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const removeFile = name => {
        setFiles(files.filter(x => x.name !== name));
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>ACC Setups</Typography>
            <Typography className={classes.disclaimer}>
                Disclaimer: Hier werden keine Setups gespeichert! Die Verareitung finde ausschließlich im Browser statt.
                Außerdem wird diese Seite der einfachheithalber nicht auf Mobilgeräten angezeigt.
            </Typography>

            {
                isMobile ?
                    <Typography className={classes.text}>
                        Mobilgerät erkannt - Seite wird hier nicht angezeigt....
                    </Typography>
                    :
                    <div className={classes.panel}>
                        <label htmlFor="upload-setup">
                            <input
                                style={{display: 'none'}}
                                id="upload-setup"
                                name="upload-setup"
                                type="file"
                                accept="application/JSON"
                                onChange={handleFileUpload}
                            />
                            <Fab
                                className={classes.uploadButton}
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                            >
                                <Add/>&nbsp;Setup hochladen
                            </Fab>
                        </label>

                        {files.map(file => {
                            return (
                                <div key={file.name}>
                                    <Grid container direction="row" alignItems="center">
                                        <Typography className={classes.text}>
                                            {file.name}
                                        </Typography>
                                        <IconButton onClick={() => removeFile(file.name)}>
                                            <Delete className={classes.uploadButton}/>
                                        </IconButton>
                                    </Grid>
                                </div>)
                        })}

                        {files.map(file => {
                            return (
                                <div key={file.name}>
                                    <Typography className={classes.text}>
                                        {file.content}
                                    </Typography>
                                </div>)
                        })}
                    </div>}
        </div>
    );
}

/*
 immer 0.01 unterschied
 TOE 0 = -0.40
 TOE 15 = -0.25
 TOE 40 = 0.00
 TOE 60 = 0.20
 */

/*
  CAMBER ??
 */

/*
 ????
 CASTER 34 = 16.8
 CASTER 30 = 15.9
 CASTER 24 = 14.5
 CASTER 10 = 11.2
 */

/*
  auto abhängig!! - Porsche beginnt bei 11 / Audi beginn bei 12
  immer 1 unterschied
  STEER RATION 1 = 13
  STEER RATIO 3 = 15
  STEER RATION 6 = 18
 */

// MECH

/*
  alles wohl 1:1 übernehmen
  ARB FRONT
  ARB REAR
 */

/*
  BREAKE TORQUE (bremskraft)
  von 0 = 80% bis 20 = 100%
 */

/*
  BREAK BIAS (bremsbalance)
  auto abhängig
  ein punkt = 0.2% bei allen autos
  Porsche 0 = 43%
  Audi 0 = 50%
 */

/*
  auto abhängig! -> nicht nur der start, sondern auch die stufen...
  WHEEL RATE 0 = 112000
  WHEEL RATE 1 = 132000
  WHEEL RATE 2 = 153000
  WHEEL RATE 3 = 173000
  WHEEL RATE 4 = 183000
 */

/*
  immer 10er schritte - bei allen Autos?
  PRELOAD 0 = 20
  PRELOAD 4 = 60
  PRELOAD 14 = 160
 */

// DAMPER

/*
  alles wohl 1:1 übernehmen - 1 punkt gleich 1 punkt ingame
  BUMP SLOW
  BUMP FAST
  REBOUND SLOW
  REBOUND FASt
 */

// AERO

/*
  alles wohl 1:1 übernehmen - 1 punkt gleich 1 punkt ingame
  BREAK DUCT 0 = 0
  BREAK DUCT 3 = 3
 */