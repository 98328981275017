const wallpaper = [
    {
        order: 1,
        text: 'McLaren 720S GT3 Evo <strong>#11</strong>',
        name: 'gt3_11',
        active: true,
    },
    {
        order: 2,
        text: 'McLaren 720S GT3 Evo <strong>#42</strong>',
        name: 'gt3_42',
        active: true,
    },
    {
        order: 3,
        text: 'McLaren 720S GT3 Evo <strong>#43</strong>',
        name: 'gt3_43',
        active: true,
    },
    {
        order: 4,
        text: 'Mercedes AMG GT3 Evo <strong>#96</strong>',
        name: 'gt3_96',
        active: true,
    },
    {
        order: 5,
        text: 'McLaren 720S GT3 Evo <strong>#128</strong>',
        name: 'gt3_128',
        active: true,
    },
    {
        order: 6,
        text: 'McLaren 720S GT3 Evo <strong>#216</strong>',
        name: 'gt3_216',
        active: true,
    },
    {
        order: 7,
        text: 'Mercedes AMG GT3 Evo <strong>#218</strong>',
        name: 'gt3_218',
        active: true,
    },
    {
        order: 8,
        text: 'McLaren 720S GT3 Evo <strong>#311</strong>',
        name: 'gt3_311',
        active: true,
    },
    {
        order: 9,
        text: 'Mercedes AMG GT3 Evo <strong>#619</strong>',
        name: 'gt3_619',
        active: true,
    },
    {
        order: 10,
        text: 'Porsche 992 GT3 R',
        name: 'gt3_porsche992_default',
        active: true,
    },
    {
        order: 11,
        text: 'Lamborghini Huracán GT3 Evo 2',
        name: 'gt3_lamboevo2_default',
        active: true,
    },
    {
        order: 12,
        text: 'Mercedes AMG GT3 Evo',
        name: 'gt3_amgevo_default',
        active: true,
    },
    {
        order: 13,
        text: 'McLaren 720S GT3 Evo',
        name: 'gt3_720evo_default',
        active: true,
    },
    {
        order: 14,
        text: 'Shooting GT3 Liveries <strong>von vorne</strong>',
        name: 'gt3_liveries',
        active: true,
    },
    {
        order: 15,
        text: 'Shooting GT3 Liveries <strong>von hinten & vorne</strong>',
        name: 'gt3_liveries_vohi',
        active: true,
    },
];

export default wallpaper.sort((a, b) => a.order > b.order ? 1 : -1);