import {makeStyles} from '@mui/styles';
import dateFormat from 'dateformat';
import buildInfo from '../buildInfo';

const buildDate = new Date(buildInfo.buildDate);
const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: '2rem',
    },
    top: {
        display: 'flex',
        color: '#e8f9f0',
        backgroundColor: '#0a0f0a',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '9px',
    },
    bottom: {
        display: 'flex',
        padding: '5px',
        color: '#e8f9f0',
        backgroundColor: '#0a0f0a',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    imgACC: {
        maxHeight: '2.5rem',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '2rem',
        },
    },
    imgIR: {
        maxHeight: '2.3rem',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '1.9rem',
        },
    },
    link: {
        color: '#e8f9f0',
    },
}));

export default function Footer() {
    const classes = useStyles();
    const year = new Date().getFullYear();

    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <img className={classes.imgACC} src="assets/acc_white.png"/>&nbsp;&nbsp;&nbsp;&nbsp;
                <img className={classes.imgIR} src="assets/iracing_white.png"/>
            </div>
            <div className={classes.bottom}>
            <span>©&nbsp;{year}&nbsp;Copyright&nbsp;<a className={classes.link} target="_blank"
                                                       href="https://m1well.com">https://m1well.com</a></span>
                <span>&nbsp;&nbsp;&nbsp;Letztes&nbsp;Update&nbsp;{dateFormat(buildDate, 'isoDate')}</span>
            </div>
        </div>
    );
}
