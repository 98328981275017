import Welcome from './Welcome';
import CommunitiesAndFriends from './CommunitiesAndFriends';
import Team from './Team';
import DriverList from './DriverList';
import Pictures from './Pictures';

export default function Content() {
    return (
        <div>
            <Welcome/>
            <DriverList/>
            <Team/>
            <CommunitiesAndFriends/>
            <Pictures/>
        </div>
    );
}
